<!-- 带快捷选择的时间选择器-->
<template>
    <div :class="['fast-choose-date-picker', timeType === 'statistics' ? 'statistics' : '']">
        <!-- 月份 -->
        <el-date-picker v-if="types === 'monthrange'" key="month" size="mini" :popper-class="'monthrange'"
            ref="fastChooseDatePickerMonth" v-model="value" :type="types" :picker-options="pickerOptions"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" prefix-icon="el-icon-time"
            @change="changeFnMonth" align="center" :clearable="false">
        </el-date-picker>
        <!-- 日期 -->
        <el-date-picker v-else key="date" :popper-class="!isSwitch ? 'popperClass' : 'popper-class-btn'"
            ref="fastChooseDatePicker" v-model="value" :type="types" :picker-options="pickerOptions" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" prefix-icon="el-icon-time" @change="changeFn" align="center"
            :clearable="false" :default-time="['0:00:00', '23:59:59']">
        </el-date-picker>
    </div>
</template>

<script>
var _types = 'monthrange';
/**
YEAR;
 * 日 DAY   月 MONTH    半年 HALFYEAR    年 YEAR
 */

var chooseTimeType = '';
// time为某一天的时间戳  获取任意一天的开始时间
function startTime(time) {
    const nowTimeDate = new Date(time)
    return new Date(nowTimeDate.setHours(0, 0, 0, 0))
}
// 获取任意一天的结束时间
function endTime(time) {
    const nowTimeDate = new Date(time)
    return new Date(nowTimeDate.setHours(23, 59, 59, 999))
}
var shortcuts = [
    {
        text: "今天",
        onClick(picker) {
            chooseTimeType = 'DAY'
            const end = new Date();
            const start = startTime(new Date().getTime());
            _types = 'daterange';
            picker.$emit("pick", [start, end]);
        }
    }, {
        text: '昨天',
        onClick(picker) {
            chooseTimeType = 'DAY'
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            _types = 'daterange';
            let start = startTime(date);
            let end = endTime(date);
            console.log(startTime(date).getTime(), endTime(date).getTime(), 333333);
            picker.$emit("pick", [start, end]);
        }
    },
    {
        text: "本周",
        onClick(picker) {
            chooseTimeType = 'WEEK'
            // 获取今天
            const end = new Date();
            // 获取当前周的第一天
            const start = startTime(new Date(
                end.getFullYear(),
                end.getMonth(),
                end.getDate() - ((end.getDay() + 6) % 7)
            ));
            start.setTime(start.getTime());
            _types = 'daterange'
            picker.$emit("pick", [start, end]);
        },
    }, {
        text: "上周",
        onClick(picker) {
            chooseTimeType = 'WEEK'
            const dataValue = new Date();
            const year = dataValue.getFullYear();
            const month = dataValue.getMonth() + 1;
            const day = dataValue.getDate();
            var thisWeekStart; //本周周一的时间
            if (dataValue.getDay() == 0) {
                //周天的情况；
                thisWeekStart =
                    new Date(year + "/" + month + "/" + day).getTime() -
                    (dataValue.getDay() + 6) * 86400000;
            } else {
                thisWeekStart =
                    new Date(year + "/" + month + "/" + day).getTime() -
                    (dataValue.getDay() - 1) * 86400000;
            }
            const prevWeekStart = thisWeekStart - 7 * 86400000; //上周周一的时间
            const prevWeekEnd = thisWeekStart - 1 * 86400000; //上周周日的时间
            const start = new Date(prevWeekStart); //开始时间
            const end = new Date(prevWeekEnd); //结束时间
            _types = 'daterange'
            picker.$emit("pick", [startTime(start), endTime(end)]);
        },
    }, {
        text: "本月",
        onClick(picker) {
            chooseTimeType = 'MONTH'
            const end = new Date();
            // 获取当前月的第一天
            const start = new Date(end.getFullYear(), end.getMonth(), 1);
            start.setTime(start.getTime());
            _types = 'daterange'
            picker.$emit("pick", [start, end]);
        },
    }, {
        text: "上月",
        onClick(picker) {
            chooseTimeType = 'MONTH'
            const oDate = new Date();
            var year = oDate.getFullYear();
            var month = oDate.getMonth();
            var start, end;
            if (month == 0) {
                year--;
                start = new Date(year, 11, 1);
                end = new Date(year, 11, 31);
            } else {
                start = new Date(year, month - 1, 1);
                end = new Date(year, month, 0);
            }
            _types = 'daterange'
            picker.$emit("pick", [startTime(start), endTime(end)]);
        },
    }, {
        text: "最近7天",
        onClick(picker) {
            chooseTimeType = 'DAY'
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
        }
    }, {
        text: "最近30天",
        onClick(picker) {
            chooseTimeType = 'DAY'
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            _types = 'daterange'
            picker.$emit("pick", [start, end]);
        }
    },
    //  {
    //     text: '上半年',
    //     onClick(picker) {
    //         chooseTimeType = 'HALFYEAR'
    //         const currentDate = new Date();
    //         const currentYear = currentDate.getFullYear();
    //         const start = new Date(currentYear, 0, 1);
    //         const end = new Date(currentYear, 5, 30).Format('yyyy-MM-dd');
    //         _types = 'monthrange'
    //         picker.$emit("pick", [startTime(start), endTime(end)]);
    //     }
    // }, {
    //     text: '下半年',
    //     onClick(picker) {
    //         chooseTimeType = 'HALFYEAR'
    //         const currentDate = new Date();
    //         const currentYear = currentDate.getFullYear();
    //         const start = new Date(currentYear, 6, 1);
    //         const end = new Date(currentYear, 11, 31);
    //         _types = 'monthrange'
    //         picker.$emit("pick", [start, end]);
    //     }
    // }, {
    //     text: '今年',
    //     onClick(picker) {
    //         chooseTimeType = 'YEAR'
    //         const currentDate = new Date();
    //         const currentYear = currentDate.getFullYear();
    //         const start = new Date(currentYear, 0, 1);
    //         const end = new Date();
    //         _types = 'monthrange'
    //         picker.$emit("pick", [start, end]);
    //     }
    // }, {
    //     text: '去年',
    //     onClick(picker) {
    //         chooseTimeType = 'YEAR'
    //         const currentDate = new Date();
    //         const currentYear = currentDate.getFullYear() - 1;
    //         const start = new Date(currentYear, 0, 1);
    //         const end = new Date(currentYear, 11, 31);
    //         _types = 'monthrange'
    //         picker.$emit("pick", [startTime(start), endTime(end)]);
    //     }
    // }
];
export default {
    data() {
        return {
            value: '',
            minDate: null,
            maxDate: null,
            pickerOptions: {
                shortcuts: shortcuts,
                // 监听每一次选择日期的事件
                onPick: ({ maxDate, minDate }) => { // 最大时间  最小时间
                    this.choiceDate = minDate.getTime() // 当选一个日期时 就是最小日期
                    // // 如何选择了两个日期了，就把那个变量置空
                    if (this.types === 'monthrange') {
                        chooseTimeType = 'MORE_MONTH';
                        this.minDate = minDate;
                        this.maxDate = maxDate;
                    } else {
                        chooseTimeType = 'DAY'
                        if (maxDate) {
                            this.choiceDate = ''
                        }
                    }
                },
                // 设置禁用状态  time是日历上的每一个时间
                disabledDate: time => {
                    // 如果选择了一个日期
                    if (this.choiceDate && this.isSwitch && this.types === 'datetimerange') {
                        // 如果选择了一个日期
                        if (this.choiceDate && this.isSwitch && this.types === 'datetimerange') {
                            // 15天的时间戳
                            const res = 30 * 24 * 3600 * 1000;//这里如果乘以30相当于限制31天以内的  所以乘以29
                            // 当前日期 - res = 30天之前
                            const minTime = this.choiceDate - res
                            // 当前日期 + res = 30天之后
                            const maxTime = this.choiceDate + res
                            return (
                                time.getTime() < minTime ||
                                time.getTime() > maxTime
                            )
                        }
                    } else if (this.choiceDate && this.isSwitch && this.types === 'monthrange') {
                        if (this.minDate !== null && this.maxDate === null) {
                            let minMonth = this.minDate.getMonth(),
                                lastYear = new Date(this.minDate).setMonth(minMonth - 24),
                                nextYear = new Date(this.minDate).setMonth(minMonth + 24);
                            // 只能选 minDate 前后一年的范围
                            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
                        }
                        return false;
                    } else {
                        // 如果没有选择日期，就限制不能选择今天及以后
                        return time.getTime() > endTime(new Date())
                    }
                }
            },
            num: 1,
            chooseTimeType: 'DAY'
        }
    },
    props: {
        type: {
            type: String,
            default: 'daterange'
        },
        isSwitch: {
            type: Boolean,
            default: false
        },
        timeType: {
            type: String,
            default: ''
        }
    },
    model: {
        prop: "value",
        event: "changeDate",
    },
    watch: {
        type: {
            handler(n) {
                let that = this;
                if (this.isSwitch) {
                    this.choiceDate = '';
                    this.minDate = '';
                    this.maxDate = '';
                    if (n === 'datetimerange') {
                        this.pickerOptions.shortcuts = [...shortcuts, ...[{
                            text: "切换月份选择",
                            onClick() {
                                that.$emit('update:type', 'monthrange')
                            },
                        }]]
                        this.value = '';
                    } else {
                        this.pickerOptions.shortcuts = [...shortcuts, ...[{
                            text: "切换日期选择",
                            onClick() {
                                that.$emit('update:type', 'datetimerange')
                            },
                        },
                        {
                            text: "确定",
                            onClick() {
                                // if(that.value !== '' && that.value.length === 2){
                                that.$emit('changeDate', that.value)
                                //通过$ref设置 点击确定时，关闭月份选择的弹窗
                                that.$nextTick(() => {
                                    that.handlerMonthClose();
                                })
                                // }
                            },
                        },]]
                        this.value = '';
                    }
                }
                this.$nextTick(() => {
                    if (n === 'monthrange') {
                        this.$refs.fastChooseDatePickerMonth.focus();
                    } else {
                        if (this.num > 1) {
                            this.$refs.fastChooseDatePicker.focus();
                        }
                        this.num++
                    }
                })
            },
            immediate: true
        },
        chooseTimeType(n) {
            console.log(n, 'nnnnnn');
        }
    },
    computed: {
        types() {
            return this.type;
        }
    },
    mounted() {
        this.num = 1;
        this.$nextTick(() => {
            this.$refs.fastChooseDatePicker.blur();
        })
        let start = '', end = '';
        if (this.timeType === 'statistics') {
            // let date = new Date();
            // date.setTime(date.getTime() - 3600 * 1000 * 24);

            // let startTime = new Date(date.setHours(0, 0, 0, 0))
            //  start = startTime.setTime(startTime.getTime());
            // let endTime = Date(date.setHours(23, 59, 59, 999))
            //  end = endTime.setTime(endTime.getTime());
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            start = startTime(date)
            end = endTime(date)

        } else {
            let nowDate = new Date('2022-08-17').getTime(new Date('2022-08-17'));
            end = new Date();
            // 获取当前周的第一天
            start = new Date(
                end.getFullYear(),
                end.getMonth(),
                end.getDate() - ((end.getDay() + 6) % 7)
            );
            if (start.getTime() < nowDate) {
                start = startTime(nowDate);;
            } else {
                start.setTime(start.getTime());
            }
            end = new Date();
        }
        // this.value = [start, end]
        this.value = ""
    },
    methods: {
        handlerMonthClose() {
            this.$refs.fastChooseDatePickerMonth.hidePicker();
        },
        changeType() {
            // if(this.value[0].getTime() > new Date().getTime() ){
            //     this.value[0]=  new Date();
            // }
            if (this.value[1].getTime() > new Date().getTime()) {
                this.value[1] = new Date();
            }
            if (this.types === 'monthrange') {
                if (_types === 'monthrange') {
                    this.$emit('changeDateType', 'monthrange')
                } else {
                    this.$emit('changeDateType', 'datetimerange')
                }
            } else {
                this.$emit('changeDateType', 'datetimerange')
            }
        },
        changeFn() {
            this.changeType();
            //   #1.此处发送change事件是为了事先v-model，
            //   #2.父组件会自动将监听接收到的值赋值给父组件定义的变量
            //   #3.在父组件也可以“显示地”监听change事件做一些额外的处理操作
            this.$emit('chooseTimeType', chooseTimeType)
            this.$emit('changeDate', this.value)
        },
        changeFnMonth() {
            this.changeType();
            if (_types === 'monthrange') {
                let year = this.value[1].getFullYear();
                let month = this.value[1].getMonth() + 1;
                console.log(year, month);
                let newDate = new Date(year, month, 0).Format('yyyy-MM-dd');
                this.value[1] = new Date(newDate);
            }
            this.$emit('chooseTimeType', chooseTimeType)
            this.$emit('changeDate', this.value)
        }
    }
}
</script>

<style lang="less">
// .fast-choose-date-picker{
.popperClass {

    // height: 374px;
    .el-picker-panel__content {

        // padding:30px 16px;
        .el-date-table td,
        .el-date-table td div {
            // height: 32px !important;
            // padding: 6px 0;
        }
    }

    .el-picker-panel__footer {
        .el-picker-panel__link-btn:first-child {
            display: none;
        }

    }
}

.popper-class-btn {
    .el-picker-panel__sidebar {
        .el-picker-panel__shortcut:last-child {
            margin-top: 14px;
            border: none;
            position: relative;
            z-index: 2;
            color: #366aff;
            cursor: pointer;
        }
    }

    .el-picker-panel__footer {
        .el-picker-panel__link-btn:first-child {
            display: none;
        }

    }
}

.monthrange {
    height: 410px;

    .el-picker-panel__body,
    .el-picker-panel__body-wrapper {
        height: 100%;
    }

    .el-picker-panel__body::after {
        width: 100%;
        height: 87px;
        border-bottom: 1px solid #dcdfe6;
    }

    .el-picker-panel__content {
        padding: 30px 16px;
        border-bottom: 1px solid #e4e4e4;

        .el-date-table td,
        .el-date-table td div {
            height: 32px !important;
            padding: 6px 0;
        }
    }

    .el-picker-panel__sidebar {
        overflow: visible;

        .el-picker-panel__shortcut:nth-child(12) {
            border-bottom: 1px solid #dcdfe6;
        }

        .el-picker-panel__shortcut:nth-child(13) {
            margin-top: 6px;
            border: none;
            position: relative;
            z-index: 2;
            color: #366aff;
            cursor: pointer;
        }

        .el-picker-panel__shortcut:nth-child(14) {
            position: absolute;
            z-index: 99999999;
            color: #366aff;
            cursor: pointer;
            left: 630px;
            bottom: 4px;
        }

        .el-picker-panel__shortcut:last-child {
            position: absolute;
            z-index: 99999999;
            cursor: pointer;
            left: 680px;
            border: 1px solid #dcdfe6;
            background-color: #fff;
            color: #606266;
            width: 54px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 6px;
        }
    }
}

.el-date-editor {
    height: 33px;

    .el-range__icon {
        line-height: 27px;
        font-size: 17px;
    }

    .el-range-separator {
        line-height: 25px !important;
    }
}

// }
</style>
